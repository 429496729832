<template>
  <div class="view-all-box">
    <div class="breadcrumb-box">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: `/school/schoolhome`}">智慧学堂</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }">{{courseType}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- <div class="screening">
      <div class="public classify">
        <span class="public_left">项目：</span>
        <ul>
          <li class="active">全部</li>
          <li v-for="(item, index) in projectList" :key="index">
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="public format">
        <span class="public_left">状态：</span>
        <ul>
          <li class="active">全部</li>
          <li v-for="(item, index) in statusList" :key="index">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div> -->
    <div class="course">
      <!-- <div class="input-box">
        <el-input
          placeholder="请选择日期"
          suffix-icon="el-icon-search"
          v-model="input1"
          size="mini"
          style="width:172px"
        >
        </el-input>
      </div> -->
      <ul class="course-ul">
        <li v-for="(item, index) in list" :key="index">
          <div class="course_box_top flex-align-between">
            <div class="box_left">{{courseType}}</div>
          </div>
          <div class="img-box">
            <img
              :src="downloadURL + item.cover"
              :onerror="$store.state.course.defaultCourseCover[index% 5]"
              alt
            />
          </div>
          <div class="flex-column-between info">
            <h3 class="course_name wes-2">{{ item.name }}</h3>
            <div class="course_speaker" v-if="courseType !== '公开课'">所属项目：{{item.project.name}}</div>
            <div class="course_speaker">主讲老师：{{item.teacherName || '无'}}</div>
            <div class="course_speaker course_time" v-if="courseType !== '公开课'">
              上课时间：{{item.timeScope}}
            </div>
            <!-- <div class="course_speed">
              <el-progress
                :percentage="index"
                v-model="item.people"
              ></el-progress>
            </div> -->

            <div class="flex-align-between other-msg">
              <span class="flex-align other_left"></span>
              <span class="button csp" @click="goPage(item,index)">查看课程</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="page-box">
        <el-pagination  
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[3, 6, 9]"
              :page-size="pagesize"
              layout="prev, pager, next,sizes"
              :total="total"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input1:'',
      projectList: [
        { name: "双师课堂" },
        { name: "共读工程" },
        { name: "武安书法" },
        { name: "和田普通话" }
      ],
      statusList: [
        { name: "进行中" },
        { name: "未开始" },
        { name: "已结束" }
      ],
      // defualtImage: [
      //   `this.src="${require("../../assets/images/course/course-cover01.png")}"`,
      //   `this.src="${require("../../assets/images/course/course-cover02.jpg")}"`,
      //   `this.src="${require("../../assets/images/course/course-cover03.jpg")}"`,
      //   `this.src="${require("../../assets/images/course/course-cover04.jpg")}"`,
      //   `this.src="${require("../../assets/images/course/course-cover05.jpg")}"`
      // ],
      list: [
        {
          name: "和孩子一起读--例说正本阅读的设计与实施",
          teacherName: "孙宏慧",
          time: "2020.07.19 11:32 - 23:10",
          lessonsPattern: 0,
          people: 65,
          project:{name:"双师联盟"}
        },
        {
          name: "校本课程| 《树之歌》",
          teacherName: "宋少卿",
          time: "2020.07.19 11:32 - 13:10",
          lessonsPattern: 1,
          people: 65,
          project:{name:"双师联盟"}
        },
        {
          name: "校本课程|《拍手歌》",
          teacherName: "宋少卿",
          time: "2020.07.219 11:32 - 13:10",
          lessonsPattern: 1,
          people: 65,
          project:{name:"双师联盟"}
        }
      ],
      currentPage:1,
      pagesize:6,
      total:0,
      courseType:"",
    };
  },
  methods: {
    async getCourseList(){
      let params = {
        page:String(this.currentPage),
        limit:String(this.pagesize),
        lessonsPattern:String(this.$route.query.pattern),
      }
      let resData = await this.$Api.Course.getCourseList(params)
      this.list = resData.data.list;
      this.total = resData.data.totalCount;
    },
    async getPublicCourseList(){
      let params = {
        page:String(this.currentPage),
        limit:String(this.pagesize),
        // lessonsPattern:String(this.$route.query.pattern),
      }
      let resData = await this.$Api.Course.getPublicList(params)
      // console.log(resData);
      this.list = resData.data.list;
      this.total = resData.data.totalCount;
    },
    //跳转页面
    goPage(item, index) {
      // console.log(item);
      let path = "/course/";
      let _coverIndex = index % 5;
      let projectID = this.$route.query.pattern != 2 ? item.project.id : 1
      this.$router.push({
        path: `/course/${item.id}`,
        query: { proid: projectID, cover: _coverIndex, from:'all' },
      });
    },
    //改变展示条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.currentPage = 1
      if(this.$route.query.pattern == 2) {
        this.getPublicCourseList();
      } else {
        this.getCourseList();
      }
       window.scrollTo(0,0);
    },
    //翻页
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val
      if(this.$route.query.pattern == 2) {
        this.getPublicCourseList();
      } else {
        this.getCourseList();
      }
      window.scrollTo(0,0);
    },
    init() {
      window.scrollTo(0,0);
      
      let map = {
        0:'同步课',
        1:'异步课',
        2:'公开课',
      }
      this.courseType = map[this.$route.query.pattern];
      if(this.$route.query.pattern == 2) {
        this.getPublicCourseList();
      } else {
        this.getCourseList();
      }
    }
  },
  activated() {
    scrollTo(0, 0);
    this.init();
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="less" scoped>
/*媒体查询*/
/*当页面大于1200px 时，大屏幕，主要是PC 端*/
@media (min-width: 1300px) {
  .breadcrumb-box {
    width: 1200px;
  }
  .screening {
    width: 1200px;
  }
  .course {
    width: 1200px;
    .course-ul {
      li {
        margin-right: 70px;
      }
    }
  }
}

/*在992 和1199 像素之间的屏幕里，中等屏幕，分辨率低的PC*/
@media (max-width: 1299px) {
  .breadcrumb-box {
    width: 900px;
  }
  .screening {
    width: 900px;
  }
  .course {
    width: 900px;
    .course-ul {
      li {
        margin-right: 35px;
      }
    }
  }
}

.view-all-box {
  width: 100%;
  background: #fafafa;
}

.breadcrumb-box {
  margin: 39px auto 20px;
}

.screening {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 47px;
  padding-bottom: 20px;
  background: #fff;
  // border:1px solid red;
  .public {
    display: flex;
    cursor: pointer;
    // align-items: center;
    // justify-content: center;
    margin-bottom: 20px;
    .public_left {
      padding-top: 3px;
      font-size: 14px;
      width: 60px;
      text-align: center;
    }
    ul {
      flex: 1;
      li {
        font-size: 14px;
        padding: 3px 20px;
        display: inline-block;
        border-radius: 20px;
      }
      .active {
        background: #2373ec;
        color: #ffffff;
      }
    }
  }
  .classify {
    // padding:20px;
  }
  .format {
    // padding:20px;
  }
}

.course {
  box-sizing: border-box;
  border-radius: 10px;
  background: #ffffff;
  margin: 20px auto 243px;
  .input-box {
    text-align: right;
    padding-top: 33px;
    margin-right: 75px;
  }
  .course-ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0 60px;
    padding-top: 43px;
    li {
      max-width: 308px;
      width: 30%;
      margin-bottom: 67px;
      box-sizing: border-box;
      padding: 20px 18px;
      background: #ffffff;
      box-shadow: 0px 5px 29px 0px rgba(22, 115, 255, 0.16);
      border-radius: 10px;
      &:nth-child(3n) {
        margin-right: 0px;
      }
    }
    .course_box_top {
      box-sizing: border-box;
      padding-bottom: 10px;
      .box_left {
        border-left: 4px solid #2474ec;
        padding-left: 10px;
        color: #2474ec;
        font-weight: 400;
        font-size: 15px;
      }
      .icon_right {
        vertical-align: middle;
        display: inline-table;
        width: 16px;
        height: 16px;
        margin-left: 5px;
        background-image: url("../../assets/images/home/icon_time.png");
        background-repeat: no-repeat;
      }
      .box_right {
        color: #ff1a1a;
        font-weight: 300;
        font-size: 14px;
      }
    }
    h3 {
      font-size: 18px;
      height: 50px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 12px;
    }
    .img-box {
      width: 100%;
      height: 166px;
      overflow: hidden;
      margin-bottom: 10px;
    }


    img {
      display: block;
      border-radius: 3px;
      background-color: #1c4b90;
      width: 100%;
    }
    .info {
      min-height: 82px;
      //   .course_name {
      //   }
      .course_speaker {
        color: #595959;
        font-size: 15px;
        font-weight: 400;
        padding-bottom: 3px;
      }
      //   .course_time {
      //   }
      .course_speed {
        padding-top: 3px;
        padding-bottom: 10px;
      }
    }
    .other-msg {
      font-size: 12px;
      color: #999;
      .other_left {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #888888;
      }
      .button {
        width: 119px;
        height: 31px;
        text-align: center;
        background: linear-gradient(90deg, #2272ec, #3a7bf6);
        box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
        border-radius: 5px;
        font-size: 14px;
        font-family: PingFang SC;
        line-height: 31px;
        color: #ffffff;
      }
      /*
    //   .time::before {
    //     content: " ";
    //     margin-right: 4px;
    //     width: 20px;
    //     height: 20px;
    //     background-image: url("../../../../static/news/time@2x.png");
    //     background-repeat: no-repeat;
    //   }*/
    }
  }
  .page-box {
    text-align: right;
    padding-bottom: 33px;
    margin-right: 75px;
  }
}
</style>